import s from './insightsContent.module.scss';
import { useTranslation } from 'react-i18next';

export const ApplicationLoanValues = () => {
  const { t } = useTranslation('Insights');
  return (
    <div className={s['container']}>
      <h1 className={s['title']}>{t('ApplicationLoanValues.application_loan_values')}</h1>
      <p className={s['details']}>{t('ApplicationLoanValues.application_loan_values_text')}</p>
      <div className={s['chart']}>Application Loan Values Chart Placeholder</div>
    </div>
  );
};

