import Select from 'react-select';
import { customSelectStyles } from '../../helpers/lineChartDefaults';
import i18n from '~/i18n';
import FilterItem from './FilterItem';
import { getDateDropdownOptions } from '../../helpers/dateDropDownOptions';
import { useTranslation } from 'react-i18next';
import { memo, useEffect, useState } from 'react';
import { FiltersState, Metric } from '../../types';
import ToggleSelect from './ToggleSelect/ToggleSelect';
import s from './chartFilters.module.scss';
import { trackDealershipMetricDateRangeSelect, trackDealershipMetricOriginSelect } from '~/tracking/avoTracking';

export interface DefaultFilters {
  [key: string]: boolean;
}

type ChartFilterProps = {
  enablePeriod?: boolean;
  enableOrigins?: boolean;
  includeDay: boolean;
  onFilterChange: (filters: FiltersState) => void;
  defaults: FiltersState | null;
  metric: Metric;
};

const ChartFilter = memo(
  ({ defaults, onFilterChange, enablePeriod, enableOrigins, includeDay, metric }: ChartFilterProps) => {
    const { t } = useTranslation('Stock');
    const [selectedPeriod, setSelectedPeriod] = useState(defaults?.period || 7);
    const [selectedOrigins, setSelectedOrigins] = useState(defaults?.origins || ['count']);

    useEffect(() => {
      const chartFilters = {
        period: selectedPeriod,
        origins: selectedOrigins
      };
      onFilterChange(chartFilters);
    }, [selectedPeriod, selectedOrigins, onFilterChange]);

    // ORIGINS TOGGLE
    const handleChangeOrigins = (newOrigins: string[]) => {
      const changeOrigin = (value: string[]) => {
        trackDealershipMetricOriginSelect({ metricName: metric, metricOriginFrom: selectedOrigins, metricOriginTo: value });
        setSelectedOrigins(value);

      };

      // If "All" already is in the array and we're adding another origin - when "All" is the first item
      if (newOrigins[0] === 'count' && newOrigins.length > 1) {
        // Remove 'All' from the filters
        changeOrigin(newOrigins.filter((origin: string) => origin !== 'count'));

        // If "All" is being added to the array when there are other origins already selected - when "All" is the last item
      } else if (newOrigins[newOrigins.length - 1] === 'count' && newOrigins.length > 1) {
        // Leave only "All" in the filters
        changeOrigin(newOrigins.filter((origin) => origin === 'count'));

        // If no filter is selected, default to "All"
      } else if (newOrigins.length === 0) {
        changeOrigin(['count']);
      } else {
        // Set the selected origins
        changeOrigin(newOrigins);
      }
    };

    // PERIOD DROPDOWN
    const dataDropdownOptions = getDateDropdownOptions(t, includeDay);
    const defaultPeriod = dataDropdownOptions.find((option) => option.value === Number(selectedPeriod));

    const handleDatePeriodChange = (value: number) => {
      trackDealershipMetricDateRangeSelect({
        metricName: metric,
        valueFrom: selectedPeriod,
        valueTo: value,
        selectOptions: dataDropdownOptions
      });

      setSelectedPeriod(value);
    };

    return (
      <div className={s['dropdownContainer']}>
        <div className={s['dropdown']}>
          {/* Render Origins Filter */}
          {enableOrigins && (
            <FilterItem label={t('ChartFilters.quote_source')}>
              <ToggleSelect<string>
                onChange={handleChangeOrigins}
                values={selectedOrigins}
                options={[
                  { id: 'count', label: t('ChartFilters.all_sources') },
                  { id: 'count showroom', label: t('ChartFilters.showroom') },
                  { id: 'count online', label: t('ChartFilters.online') }
                ]}
              />
            </FilterItem>
          )}
          {/* Render Period Filter */}
          {enablePeriod && (
            <FilterItem key="default-datePeriod" label={t('ChartFilters.date_period')}>
              <Select
                options={dataDropdownOptions}
                defaultValue={defaultPeriod}
                styles={customSelectStyles}
                onChange={({ value }) => handleDatePeriodChange(value)}
                key={i18n.language}
                isSearchable={false}
              />
            </FilterItem>
          )}
        </div>
      </div>
    );
  }
);

export default ChartFilter;
