import { useRef, useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '~/components/Common/Breadcrumbs';
import Page from '~/components/Common/Page';
import Panel from '~/components/Common/Panel';
import PanelHeader from '~/components/Common/PanelHeader';
import './quickQuoteFindVehicle.scss';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import RadioButtonGroup from '../../Common/Form/RadioButtonGroup';
import VrmLookup from '~/components/VrmLookup/VrmLookup';
import VisLookUp from '~/components/Common/VisLookup/context/VisLookupContext';
import { SearchBox, Hits, Pagination } from '~/components/Common/VisLookup/components';
import { useSelectedVehicle, useMetaData, useSearchState, useSearchQuery } from '~/components/Common/VisLookup/hooks';
import PanelContent from '~/components/Common/PanelContent';
import { useTranslation } from 'react-i18next';
import { withRouter } from '~/hocs/router';
import { formatVehicleObject } from './utils';
import { useNavigate } from '~/hooks/useNavigate';
import appStore from '~/mobx-stores/appStore';
import { Tooltip } from '../../Common/Tooltip/Tooltip';

const QuickQuoteFindVehicle = (props: any) => {
  const { t } = useTranslation('QuickQuote');
  const { selectedVehicleId } = useSelectedVehicle();
  const { refine: setSearchQuery, query } = useSearchQuery();
  const navigate = useNavigate();
  const searchBarRef = useRef(null);
  const { isDe } = appStore.uiState;

  const [error, setError] = useState(false);
  const [condition, setCondition] = useState('used');
  const [vrm, setVrm] = useState('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [hasMultipleDerivatives, setHasMultipleDerivatives] = useState(false);
  const [vrmError, setVrmError] = useState(false);

  const VrmOptions = [
    { key: 'used', value: t('FindAVehicle.used') },
    { key: 'new', value: t('FindAVehicle.new') }
  ];

  const searchState = useSearchState();
  const { vehicles } = useMetaData();
  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
  const displayPagination = !selectedVehicleId && vehicles.length > 0 && searchState === 'idle';

  // Set initial values from query params
  useEffect(() => {
    if (props.query.vehicleType?.toLowerCase() === 'new' || props.query.vehicleType?.toLowerCase() === 'used') {
      setCondition(decodeURIComponent(props.query.vehicleType?.toLowerCase()));
    }
    if (props.query.vrm) {
      setVrm(decodeURIComponent(props.query.vrm).toUpperCase());
    }
    if (props.query.search) {
      setSearchQuery(decodeURIComponent(props.query.search));
    }
    if (props.query.multipleDerivatives && props.quickQuote?.vehicle?.RawVehicle?.CapCode) {
      setHasMultipleDerivatives(decodeURIComponent(props.query.multipleDerivatives) === 'true');
      setSearchQuery(decodeURIComponent(props.quickQuote?.vehicle?.RawVehicle?.CapCode));
    }
  }, [props.query.vehicleType]);

  // Update query params with the algolia search input values
  useEffect(() => {
    if (!hasMultipleDerivatives) {
      props.navigate({
        pathname: props.location.pathname,
        query: { ...props.query, search: encodeURIComponent(query) }
      });
    }
  }, [props.location.pathname, props.navigate, query]);

  const handleFetchVehicleSuccess = useCallback(
    (vehicle) => {
      if (vrm) {
        vehicle.Vrm = vrm;
      }
      const editingVehicle = props.quickQuote?.editingVehicle;
      const updatedVehicle = {
        ...vehicle,
        Mileage: editingVehicle?.Mileage || vehicle.Mileage,
        RegistrationDate: editingVehicle?.RegistrationDate || vehicle.RegistrationDate,
        Price: editingVehicle?.Price || vehicle.Price,
        IsEdited: !!editingVehicle
      };
      props.onVehicleChange(updatedVehicle);
    },
    [props, vrm]
  );

  useEffect(() => {
    if (selectedVehicleId) {
      const vehicle = vehicles.find((vehicle: any) => vehicle.objectID === selectedVehicleId);
      const updatedVehicle = formatVehicleObject(vehicle, condition);

      handleFetchVehicleSuccess(updatedVehicle);
    }
  }, [selectedVehicleId, props, handleFetchVehicleSuccess, condition, vehicles]);

  const handleChangeCondition = (value: string) => {
    setCondition(value);

    if (value === 'new') {
      setVrmError(false);
    }

    props.navigate({
      pathname: props.location.pathname,
      query: { ...props.query, vehicleType: encodeURIComponent(value) }
    });
  };

  const handleVrmBlur = (name: string, value: string) => {
    props.navigate({
      pathname: props.location.pathname,
      query: { ...props.query, vrm: encodeURIComponent(value.toLowerCase()) }
    });
  };

  const handleChangeVrm = (_: string, value: string) => {
    setVrm(value.toUpperCase());
    setVrmError(condition === 'used' && !value);
  };

  const prepareVehicleData = (capVehicle: any) => {
    if (capVehicle.AccuracyScore === 2 || capVehicle.AccuracyScore === 3) {
      navigate({
        pathname: `/d/${props.params.dealershipId}/stock/${capVehicle.Id}/fix-issues`
      });
      return;
    }

    let updatedVehicle = capVehicle.CapData ? { ...capVehicle.CapData[0] } : { ...capVehicle };

    updatedVehicle.Vin = capVehicle.Vin;
    updatedVehicle.DerivativeId = updatedVehicle.CapId;

    if (capVehicle.CapData && capVehicle.CapData.length > 1) {
      setHasMultipleDerivatives(true);
      setSearchQuery(updatedVehicle.CapCode);
    } else {
      updatedVehicle = formatVehicleObject(updatedVehicle, condition, vrm);
      handleFetchVehicleSuccess(updatedVehicle);
    }
  };

  return (
    <Page>
      <Breadcrumbs
        items={[
          {
            name: t('FindAVehicle.home'),
            path: `/d/${props.params.dealershipId}`
          },
          {
            name: t('FindAVehicle.find_a_vehicle'),
            path: `/d/${props.params.dealershipId}/findavehicle`
          }
        ]}
      />
      <Panel>
        <PanelHeader>{t('FindAVehicle.find_a_vehicle')}</PanelHeader>
        <PanelContent>
          <MobxForm onSubmit={() => {}} focusOnFirstElement className="vehicleForm">
            <div className={!props.fullWidth ? 'vehicleForm__inner' : ''}>
              <MobxFormFieldGroup isInlineWide>
                <MobxFormLabel>{t('FindAVehicle.what_is_the_condition_of_the_vehicle')}</MobxFormLabel>

                <RadioButtonGroup
                  options={VrmOptions}
                  name="Condition"
                  onChange={(e) => handleChangeCondition(e.target.value)}
                  checkedValue={condition}
                  isGroupDisabled={false}
                  variant="inline"
                />
              </MobxFormFieldGroup>

              <MobxFormFieldGroup isInlineWide error={vrmError}>
                <MobxFormLabel htmlFor="Vrm">
                  <Tooltip
                    content={t(`FindAVehicle.you_don't_need a_vehicle_identifier`)}
                    open={isTooltipOpen}
                    defaultOpen={false}
                    onOpenChange={(e: boolean) => (condition === 'new' ? setIsTooltipOpen(e) : null)}
                    variant="message"
                  >
                    <div>
                      {isDe ? (
                        <div>{t('FindAVehicle.enter_dealer_reference')}</div>
                      ) : (
                        <div>{t('FindAVehicle.enter_the_vrm_or_vin')}</div>
                      )}
                    </div>
                  </Tooltip>
                </MobxFormLabel>
                <VrmLookup
                  id="Vrm"
                  onChange={handleChangeVrm}
                  onFetchVehicle={() => setError(false)}
                  onFetchVehicleError={() => setError(true)}
                  onFetchVehicleSuccess={prepareVehicleData}
                  onVrmBlur={handleVrmBlur}
                  value={vrm}
                  dealershipId={props.params.dealershipId}
                  showLookupButton={true}
                  tryAlgoliaLookup={true}
                  disabled={false}
                />
                {vrmError && condition === 'used' && (
                  <>
                    {isDe ? (
                      <div className="quickQuoteFindVehicle__vrmMandatory">
                        {t('FindAVehicle.dealer_reference_is_mandatory_for_used_vehicles')}
                      </div>
                    ) : (
                      <div className="quickQuoteFindVehicle__vrmMandatory">
                        {t('FindAVehicle.vrm_is_mandatory_for_a_used_car')}
                      </div>
                    )}
                  </>
                )}
              </MobxFormFieldGroup>
              {((vrm && (error || hasMultipleDerivatives || props.quickQuote?.editingVehicle)) ||
                condition === 'new') && (
                <>
                  {error && (
                    <div className="quickQuoteFindVehicle__vrmError">
                      {t('FindAVehicle.we_couldnt_find_the_exact_vehicle_match_from_your_stock')}
                    </div>
                  )}
                  {hasMultipleDerivatives && (
                    <div className="quickQuoteFindVehicle__vrmError">{t('FindAVehicle.confirm_derivative')}</div>
                  )}
                  {!hasMultipleDerivatives && <SearchBox ref={searchBarRef} />}
                  <Hits />
                  {displayPagination && <Pagination onChange={() => scrollToRef(searchBarRef)} />}
                </>
              )}
            </div>
          </MobxForm>
        </PanelContent>
      </Panel>
    </Page>
  );
};

const QuickQuoteFindVehicleContainer = ({ children, ...props }: any) => {
  return (
    <VisLookUp dealershipId={props.params.dealershipId}>
      <QuickQuoteFindVehicle {...props} />
    </VisLookUp>
  );
};

export default withRouter(QuickQuoteFindVehicleContainer);
