import { inject } from 'mobx-react';
import { useCallback, useState } from 'react';
import ChartFilters from '../../components/ChartFilter/ChartFilters';
import ChartContainer from '../../components/ChartContainer/ChartContainer';
import LineChart from '../../components/LineChart/LineChart';
import { FiltersState } from '../../types';

const OnlineQuotes = () => {
  const [filters, setFilters] = useState<FiltersState>();

  const handleFilterChange = useCallback(
    (chartFilters: FiltersState) => {
      setFilters(chartFilters);
    },
    [setFilters]
  );

  return (
    <ChartContainer>
      <ChartFilters
        metric="finance_quotes_displayed:count"
        defaults={{
          period: 7,
          origins: ['count']
        }}
        onFilterChange={handleFilterChange}
        enablePeriod
        enableOrigins
        includeDay
      />
      <LineChart metric={'finance_quotes_displayed:count'} filters={filters} showLegend />
    </ChartContainer>
  );
};

export default inject('appStore')(OnlineQuotes);
