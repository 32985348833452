import { useTranslation } from 'react-i18next';
import s from './insightsContent.module.scss';

export const AverageVehiclesInStock = () => {
  const { t } = useTranslation('Insights');
  return (
    <div className={s['container']}>
      <h1 className={s['title']}>{t('AverageVehiclesInStock.average_vehicles_in_stock')}</h1>
      <p className={s['details']}>{t('AverageVehiclesInStock.average_vehicles_in_stock_text')} </p>
      <div className={s['chart']}>Average Vehicles In Stock Chart Placeholder</div>
    </div>
  );
};

