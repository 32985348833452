import { useTranslation } from 'react-i18next';
import { AppStore } from '~/modules/Stock/types/Types';
import { inject } from 'mobx-react';
import s from './insightsContent.module.scss';

export type ApplicationVolumesProps = {
  appStore?: AppStore;
};

const ApplicationVolumes = ({ appStore }: ApplicationVolumesProps) => {
  const { t } = useTranslation('Insights');
  const { hasSingleLender, canSeeFinanceInsights } = appStore!.uiState;

  if (!canSeeFinanceInsights) {
    return null;
  }
  return (
    <div className={s['container']}>
      <h1 className={s['title']}>{t('ApplicationVolumes.application_volumes')}</h1>
      <p className={s['details']}>
        {t('ApplicationVolumes.application_volumes_text')}
        {!hasSingleLender && t('ApplicationVolumes.not_all_lenders')}
      </p>
      <div className={s['chart']}>Application Volumes Chart Placeholder</div>
    </div>
  );
};

export default inject('appStore')(ApplicationVolumes);