import { useTranslation } from 'react-i18next';
import s from './insightsContent.module.scss';

export const VehicleSalesData = () => {
  const { t } = useTranslation('Insights');
  return (
    <div className={s['container']}>
      <h1 className={s['title']}>{t('VehicleSalesData.vehicle_sales_data')}</h1>
      <p className={s['details']}>{t('VehicleSalesData.vehicle_sales_data_text')}</p>
      <div className={s['chart']}>Vehicle Sales Data Chart Placeholder</div>
    </div>
  );
};

