import { Route, Routes } from 'react-router-dom';
import AccountSettingsContainer from '../components/ManageUsers/containers/AccountSettingsContainer';
import AddCustomerContainer from '../components/AddCustomer/containers/AddCustomerContainer';
import AddVehicleContainer from '../components/AddVehicle/containers/AddVehicleContainer';
import AddressHistory from '../components/Application/components/AddressHistory';
import AddUser from '../components/ManageUsers/components/AddUser';
import Affordability from '../components/Application/components/Affordability';
import AppContainer from './AppContainer';
import ApplicationContainer from '../components/Application/containers/ApplicationContainer';
import ApplicationFormLayout from '../components/Application/components/ApplicationFormLayout';
import ApplicationRedirector from '../components/Application/components/ApplicationRedirector';
import ApplicationRepropose from '../components/Application/components/ApplicationRepropose';
import ApplicationStatus from '../components/Application/components/ApplicationStatus';
import ApplicationSummary from '../components/Application/components/ApplicationSummary';
import BankDetails from '../components/Application/components/BankDetails';
import BusinessAddress from '../components/Application/components/BusinessAddress';
import BusinessDetails from '../components/Application/components/BusinessDetails';
import CfcCheckDetailsContainer from '../components/CfcCheckDetails/containers/CfcCheckDetailsContainer';
import CfcProspectsContainer from '../components/Dashboard/containers/CfcProspectsContainer';
import ChangePassword from '../components/ChangePassword/components/ChangePassword';
import CloseDeal from '../components/CloseDeal/CloseDeal';
import Consumer from '../components/Customer/pages/Consumer';
import CustomerListContainer from '../components/CustomerList/containers/CustomerListContainer';
import CustomerContainer from '../components/Customer/containers/CustomerContainer';
import CustomerQuote from '../components/CustomerQuote/components/CustomerQuote';
import CustomerQuoteContainer from '../components/CustomerQuote/components/CustomerQuoteContainer';
import DashboardContainer from '../components/Dashboard/containers/DashboardContainer';
import DeadLead from '../components/CloseDeal/DeadLead';
import DealershipContainer from '../components/Misc/containers/DealershipContainer';
import DeleteCustomer from '../components/DeleteCustomer/DeleteCustomer';
import EditApplication from '../components/Application/components/EditApplication';
import EditApplicationAffordability from '../components/Application/components/EditApplicationAffordability';
import EditApplicationAffordabilityContainer from '../components/Application/containers/EditApplicationAffordabilityContainer';
import EditApplicationBankDetails from '../components/Application/components/EditApplicationBankDetails';
import EditApplicationBankDetailsContainer from '../components/Application/containers/EditApplicationBankDetailsContainer';
import EditApplicationEmploymentHistoryContainer from '../components/Application/containers/EditApplicationEmploymentHistoryContainer';
import EditApplicationQuote from '../components/Application/components/EditApplicationQuote';
import EditApplicationQuotePreSubmission from '../components/Application/components/EditApplicationQuotePreSubmission';
import EditApplicationRedirector from '../components/Application/components/EditApplicationRedirector';
import EditApplicationSummary from '../components/Application/components/EditApplicationSummary';
import EditApplicationVehicle from '../components/Application/components/EditApplicationVehicle';
import EditCustomerContainer from '../components/EditCustomer/containers/EditCustomerContainer';
import EmploymentHistory from '../components/Application/components/EmploymentHistory';
import ExportClosedLeadsContainer from '../components/ExportClosedLeads/ExportClosedLeadsContainer';
import FinanceApplicationsContainer from '../components/Dashboard/containers/FinanceApplicationsContainer';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';
import ForgotPasswordRequested from '../components/ForgotPassword/ForgotPasswordRequested';
import GroupReport from '../components/DigitalReport/pages/GroupReport';
import IndexContainer from './IndexContainer';
import LatestDeals from '../components/LatestDeals/containers/LatestDealsContainer';
import LeagueTable from '../components/LeagueTable/pages/LeagueTable';
import LenderUpdatesContainer from '../components/LenderUpdatesPage/containers/LenderUpdatesContainer';
import MarkAsNotAvailable from '../components/CloseDeal/MarkAsNotAvailable';
import OnlineReport from '../components/DigitalReport/pages/OnlineReport';
import OrganisationContacts from '../components/Application/components/OrganisationContacts';
import PageNotFound from '../components/Common/PageNotFound';
import PaidOut from '../components/PaidOut/PaidOut';
import PersonalDetails from '../components/Application/components/PersonalDetails';
import PlatformUpdatesContainer from '../components/PlatformUpdates/containers/PlatformUpdatesContainer';
import QuickQuoteApply from '../components/QuickQuote/components/QuickQuoteApply';
import QuickQuoteContainer from '../components/QuickQuote/containers/QuickQuoteContainer';
import QuickQuoteResults from '../components/QuickQuote/components/QuickQuoteResults';
import Reporting from '../components/Reporting/Reporting';
import ResetPassword from '../components/ForgotPassword/ResetPassword';
import SelfServiceQuoteContainer from '../components/SelfServiceQuote/containers/SelfServiceQuoteContainer';
import ResetPasswordSuccess from '../components/ForgotPassword/ResetPasswordSuccess';
import SettingsContainer from '../components/Settings/containers/SettingsContainer';
import SettingsDealershipContainer from '../components/DealershipSettings/containers/SettingsDealershipContainer';
import SettingsProductContainer from '../components/ProductSettings/containers/SettingsProductContainer';
import ValueAddedProductContainer from '../components/ValueAddedProductSettings/ValueAddedProductContainer';
import ValueAddedProductSettings from '../components/ValueAddedProductSettings/ValueAddedProductSettings';
import AddValueAddedProductForm from '../components/ValueAddedProductSettings/AddValueAddedProductForm';
import EditValueAddedProductForm from '../components/ValueAddedProductSettings/EditValueAddedProductForm';
import ManageUsers from '../components/ManageUsers/components/ManageUsers';
import ManageUsersContainer from '../components/ManageUsers/containers/ManageUsersContainer';
import SignedInContainer from '../components/Misc/containers/SignedInContainer';
import SignedOutLayout from '../components/Common/layouts/SignedOutLayout';
import SignInContainer from '../components/Login/containers/SignInContainer';
import SoldCash from '../components/CloseDeal/SoldCash';
import SoldFinance from '../components/CloseDeal/SoldFinance';
import User from '../components/ManageUsers/components/User';
import ViewApplicationSummary from '../components/Application/components/ViewApplicationSummary';
import ViewLenderNotes from '../components/Application/components/ViewLenderNotes';
import SavedQuoteDetailsContainer from '../components/SavedQuoteDetails/containers/SavedQuoteDetailsContainer';
import ReserveNowContainer from '../components/ReserveNow/containers/ReserveNowContainer';
import ReserveNowSettings from '../components/ReserveNow/containers/ReserveNowSettings';
import RepExampleSettingsContainer from '../components/RepExampleSettings/containers/RepExampleSettingsContainer';
import PushDealContainer from '../components/PushDeal/PushDealContainer';
import InsightsContainer from '~/components/Insights/InsightsContainer';
import PushDealActivityContainer from '../components/PushDeal/PushDealActivityContainer';
import DigitalDealsContainer from '../components/DigitalDeals/DigitalDealsContainer';
import OrderSummary from '../components/DigitalDeals/components/OrderSummary';
import DigitalDeals from '../components/DigitalDeals/components/DigitalDeals';
import InitialDiscloseDocumentSettings from '../components/Settings/components/InitialDiscloseDocumentSettings';
import BroadcastSettings from '../components/BroadcastSettings/containers/BroadcastSettingsContainer';
import Webshop from '../components/Webshop/Webshop';
import stockRoutes from '../modules/Stock/routes';
import StockSettings from '../components/Settings/pages/StockSettings';
import Impressum from '../components/Impressum/Impressum';
import Error from '../components/Common/Error/Error';
import TrackedPage from 'components/TrackedPage';
import ApplicationRequote from '~/components/Application/components/ApplicationRequote/ApplicationRequote';
import { QuickQuoteVehicleRoutes } from '~/components/QuickQuote/routes';
import ApplicationVolumes from '~/components/Insights/InsightsContent/ApplicationVolumes';
import { ApplicationLoanValues } from '~/components/Insights/InsightsContent/ApplicationLoanValues';
import { AverageVehiclesInStock } from '~/components/Insights/InsightsContent/AverageVehiclesInStock';
import { VehicleSalesData } from '~/components/Insights/InsightsContent/VehicleSalesData';
import HomePage from '~/components/Home/HomePage';

const routes = (
  <Routes>
    <Route element={<AppContainer />}>
      <Route element={<SignedOutLayout />}>
        <Route
          path="auth/signin"
          element={
            <TrackedPage pageName="Sign In">
              <SignInContainer />
            </TrackedPage>
          }
        />
        <Route
          path="auth/forgotpassword"
          element={
            <TrackedPage pageName="Forgot Password">
              <ForgotPassword />
            </TrackedPage>
          }
        />
        <Route
          path="auth/forgotpasswordrequested"
          element={
            <TrackedPage pageName="Forgot Password Requested">
              <ForgotPasswordRequested />
            </TrackedPage>
          }
        />
        <Route
          path="auth/passwordchange"
          element={
            <TrackedPage pageName="Reset Password">
              <ResetPassword />
            </TrackedPage>
          }
        />
        <Route
          path="auth/passwordchangesuccess"
          element={
            <TrackedPage pageName="Reset Password Success">
              <ResetPasswordSuccess />
            </TrackedPage>
          }
        />
      </Route>

      <Route element={<SignedInContainer />}>
        <Route index element={<IndexContainer />} />

        <Route element={<DealershipContainer />}>
          <Route
            element={
              <TrackedPage pageName="Home Page">
                <DashboardContainer />
              </TrackedPage>
            }
            path="d/:dealershipId"
          />
          <Route
            element={
              <TrackedPage pageName="Home Page V2">
                <HomePage />
              </TrackedPage>
            }
            path="d/:dealershipId/home"
          />
          <Route
            element={
              <TrackedPage pageName="Error">
                <Error />
              </TrackedPage>
            }
            path="d/:dealershipId/error(/:errorCode)"
          />
          <Route path="d/:dealershipId/financeapplications" element={<FinanceApplicationsContainer />} />
          <Route path="d/:dealershipId/cfcprospects" element={<CfcProspectsContainer />} />
          <Route path="d/:dealershipId/lenderupdatespage" element={<LenderUpdatesContainer />} />
          <Route path="d/:dealershipId/send-deal" element={<PushDealContainer />} />

          <Route path="d/:dealershipId/insights" element={<InsightsContainer />}>
            <Route
              path="application-volumes"
              element={
                <TrackedPage pageName="ApplicationVolumes - Insights">
                  <ApplicationVolumes />
                </TrackedPage>
              }
            />
            <Route path="application-loan-values" element={<ApplicationLoanValues />} />
            <Route path="average-vehicles-stock" element={<AverageVehiclesInStock />} />
            <Route path="vehicle-sales-data" element={<VehicleSalesData />} />
          </Route>

          <Route path="d/:dealershipId/orders-and-deals" element={<DigitalDealsContainer />}>
            <Route
              index
              element={
                <TrackedPage pageName="Orders & Deals">
                  <DigitalDeals />
                </TrackedPage>
              }
            />
            <Route
              path="order/:orderId"
              element={
                <TrackedPage pageName="Order Summary - Orders & Deals">
                  <OrderSummary />
                </TrackedPage>
              }
            />
          </Route>
          <Route
            path="d/:dealershipId/customer/add"
            element={
              <TrackedPage pageName="Add - Customer">
                <AddCustomerContainer />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/latestdeals"
            element={
              <TrackedPage pageName="Latest Deals">
                <LatestDeals />
              </TrackedPage>
            }
          />

          <Route
            path="d/:dealershipId/impressum"
            element={
              <TrackedPage pageName="Impressum">
                <Impressum />
              </TrackedPage>
            }
          />

          <Route
            path="d/:dealershipId/settings"
            element={
              <TrackedPage pageName="Settings">
                <SettingsContainer />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/settings/dealership"
            element={
              <TrackedPage pageName="Global Defaults - Settings">
                <SettingsDealershipContainer />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/settings/product"
            element={
              <TrackedPage pageName="Finance Products - Settings">
                <SettingsProductContainer />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/settings/reserve"
            element={
              <TrackedPage pageName="Vehicle Reservation - Settings">
                <ReserveNowSettings />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/settings/repexample"
            element={
              <TrackedPage pageName="Rep Example - Settings">
                <RepExampleSettingsContainer />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/settings/idd"
            element={
              <TrackedPage pageName="Initial Disclosure Document - Settings">
                <InitialDiscloseDocumentSettings />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/settings/broadcast"
            element={
              <TrackedPage pageName="Broadcast - Settings">
                <BroadcastSettings />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/settings/broadcast/:subscriptionId"
            element={
              <TrackedPage pageName="Broadcast - Settings">
                <BroadcastSettings />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/settings/stocksettings"
            element={
              <TrackedPage pageName="Stock - Settings">
                <StockSettings />
              </TrackedPage>
            }
          />

          <Route path="d/:dealershipId/settings/vaps" element={<ValueAddedProductContainer />}>
            <Route
              index
              element={
                <TrackedPage pageName="Value Added Products - Settings">
                  <ValueAddedProductSettings />
                </TrackedPage>
              }
            />
            <Route
              path="add"
              element={
                <TrackedPage pageName="Add Value Added Products - Settings">
                  <AddValueAddedProductForm />
                </TrackedPage>
              }
            />
            <Route
              path="edit/:vapId"
              element={
                <TrackedPage pageName="Edit Value Added Products - Settings">
                  <EditValueAddedProductForm />
                </TrackedPage>
              }
            />
          </Route>

          <Route
            path="d/:dealershipId/settings/changepassword"
            element={
              <TrackedPage pageName="Change Password - Settings">
                <ChangePassword />
              </TrackedPage>
            }
          />

          <Route path="d/:dealershipId/settings/users" element={<ManageUsersContainer />}>
            <Route
              index
              element={
                <TrackedPage pageName="Manage Users - Settings">
                  <ManageUsers />
                </TrackedPage>
              }
            />
            <Route
              path="adduser"
              element={
                <TrackedPage pageName="Add User - Settings">
                  <AddUser />
                </TrackedPage>
              }
            />
            <Route
              path=":userId"
              element={
                <TrackedPage pageName="Edit User - Settings">
                  <User />
                </TrackedPage>
              }
            />
          </Route>

          <Route
            path="d/:dealershipId/settings/account"
            element={
              <TrackedPage pageName="My Account - Settings">
                <AccountSettingsContainer />
              </TrackedPage>
            }
          />

          <Route
            path="d/:dealershipId/platformupdates"
            element={
              <TrackedPage pageName="Platform Updates">
                <PlatformUpdatesContainer />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/reporting"
            element={
              <TrackedPage pageName="Reporting">
                <Reporting />
              </TrackedPage>
            }
          />

          {stockRoutes}

          <Route path="d/:dealershipId/webshop" element={<Webshop />} />
          <Route path="d/:dealershipId/webshop/:section" element={<Webshop />} />

          <Route
            path="d/:dealershipId/reporting/exportclosedleads"
            element={
              <TrackedPage pageName="Export Closed Leads - Reporting">
                <ExportClosedLeadsContainer />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/reporting/onlinereport"
            element={
              <TrackedPage pageName="Online Report - Reporting">
                <OnlineReport />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/reporting/groupreport"
            element={
              <TrackedPage pageName="Group Report - Reporting">
                <GroupReport />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/reporting/leaguetable"
            element={
              <TrackedPage pageName="League Table - Reporting">
                <LeagueTable />
              </TrackedPage>
            }
          />
          <Route path="d/:dealershipId/quickquote" element={<QuickQuoteContainer />}>
            <Route index element={<QuickQuoteVehicleRoutes />} />
            <Route
              path="results"
              element={
                <TrackedPage pageName="Results - Quick Quote">
                  <QuickQuoteResults />
                </TrackedPage>
              }
            />
            <Route
              path="apply"
              element={
                <TrackedPage pageName="Apply - Quick Quote">
                  <QuickQuoteApply />
                </TrackedPage>
              }
            />
          </Route>

          <Route
            path="d/:dealershipId/consumers"
            element={
              <TrackedPage pageName="List - Customer">
                <CustomerListContainer />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/consumers/:consumerId/edit"
            element={
              <TrackedPage pageName="Edit - Customer">
                <EditCustomerContainer />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/consumers/:consumerId/deletecustomer"
            element={
              <TrackedPage pageName="Delete - Customer">
                <DeleteCustomer />
              </TrackedPage>
            }
          />
          <Route
            path="d/:dealershipId/consumers/:consumerId/add"
            element={
              <TrackedPage pageName="Add Vehicle - Customer">
                <AddVehicleContainer />
              </TrackedPage>
            }
          />
          <Route path="d/:dealershipId/consumers/:consumerId" element={<CustomerContainer />}>
            <Route
              index
              element={
                <TrackedPage pageName="Customer">
                  <Consumer />
                </TrackedPage>
              }
            />
            <Route
              path="cfcprospect/:cfcProspectId/quote/:quoteId"
              element={
                <TrackedPage pageName="CFC Prospect Quote - Customer">
                  <CfcCheckDetailsContainer />
                </TrackedPage>
              }
            />
            <Route
              path="saved-quotes/:quoteId"
              element={
                <TrackedPage pageName="Saved Quote - Customer">
                  <SavedQuoteDetailsContainer />
                </TrackedPage>
              }
            />
            <Route
              path="vehicle/:vehicleId/selfservicequote/:quoteId"
              element={
                <TrackedPage pageName="Vehicle Self Service Quote - Customer">
                  <SelfServiceQuoteContainer />
                </TrackedPage>
              }
            />
            <Route
              path="send-deal/:dealId"
              element={
                <TrackedPage pageName="Deal - Customer">
                  <PushDealActivityContainer />
                </TrackedPage>
              }
            />
          </Route>

          <Route path="d/:dealershipId/consumers/:consumerId/vehicle/:vehicleId/close" element={<CloseDeal />}>
            <Route
              path="soldfinance"
              element={
                <TrackedPage pageName="Sold Finance - Close Deal">
                  <SoldFinance />
                </TrackedPage>
              }
            />
            <Route
              path="notavailable"
              element={
                <TrackedPage pageName="Not Available - Close Deal">
                  <MarkAsNotAvailable />
                </TrackedPage>
              }
            />
            <Route
              path="soldcash"
              element={
                <TrackedPage pageName="Sold Cash - Close Deal">
                  <SoldCash />
                </TrackedPage>
              }
            />
            <Route
              path="notofinterest"
              element={
                <TrackedPage pageName="Not Of Interest - Close Deal">
                  <DeadLead />
                </TrackedPage>
              }
            />
          </Route>

          <Route path="d/:dealershipId/consumers/:consumerId/vehicle/:vehicleId/paidout" element={<PaidOut />} />

          <Route element={<CustomerQuoteContainer />}>
            <Route
              path="d/:dealershipId/consumers/:consumerId/vehicle/:vehicleId/quote"
              element={
                <TrackedPage pageName="Quote - Customer">
                  <CustomerQuote />
                </TrackedPage>
              }
            />
          </Route>

          <Route
            path="d/:dealershipId/reservenow"
            element={
              <TrackedPage pageName="Reservations">
                <ReserveNowContainer />
              </TrackedPage>
            }
          />

          <Route
            path="d/:dealershipId/consumers/:consumerId/application/:applicantId"
            element={<ApplicationContainer />}
          >
            <Route element={<ApplicationFormLayout />}>
              <Route index element={<ApplicationRedirector />} />

              <Route
                path="personaldetails"
                element={
                  <TrackedPage pageName="Personal Details - Application">
                    <PersonalDetails />
                  </TrackedPage>
                }
              />
              <Route
                path="addresshistory"
                element={
                  <TrackedPage pageName="Address - Application">
                    <AddressHistory />
                  </TrackedPage>
                }
              />
              <Route
                path="employmenthistory"
                element={
                  <TrackedPage pageName="Employment - Application">
                    <EmploymentHistory />
                  </TrackedPage>
                }
              />
              <Route
                path="bankdetails"
                element={
                  <TrackedPage pageName="Bank Details - Application">
                    <BankDetails />
                  </TrackedPage>
                }
              />
              <Route
                path="affordability"
                element={
                  <TrackedPage pageName="Affordability - Application">
                    <Affordability />
                  </TrackedPage>
                }
              />
              <Route
                path="applicationsummary"
                element={
                  <TrackedPage pageName="Summary - Application">
                    <ApplicationSummary />
                  </TrackedPage>
                }
              />

              <Route
                path="businessdetails"
                element={
                  <TrackedPage pageName="Business Details - Application">
                    <BusinessDetails />
                  </TrackedPage>
                }
              />
              <Route
                path="businessaddress"
                element={
                  <TrackedPage pageName="Business Address - Application">
                    <BusinessAddress />
                  </TrackedPage>
                }
              />
              <Route
                path="organisationcontacts"
                element={
                  <TrackedPage pageName="Organisation Contacts - Application">
                    <OrganisationContacts />
                  </TrackedPage>
                }
              />
            </Route>

            <Route
              path="status"
              element={
                <TrackedPage pageName="Status - Application">
                  <ApplicationStatus />
                </TrackedPage>
              }
            />

            <Route
              path="repropose"
              element={
                <TrackedPage pageName="Repropose - Application">
                  <ApplicationRepropose />
                </TrackedPage>
              }
            />
            <Route
              path="requote"
              element={
                <TrackedPage pageName="Intercept Quote Result">
                  <ApplicationRequote />
                </TrackedPage>
              }
            />

            <Route
              path="lendernotes/:timestamp"
              element={
                <TrackedPage pageName="Lender Notes - Application">
                  <ViewLenderNotes />
                </TrackedPage>
              }
            />
            <Route
              path="viewapplicationsummary"
              element={
                <TrackedPage pageName="View Summary - Application">
                  <ViewApplicationSummary />
                </TrackedPage>
              }
            />

            <Route
              path="quotepresubmission"
              element={
                <TrackedPage pageName="Quote Pre Submission - Application">
                  <EditApplicationQuotePreSubmission />
                </TrackedPage>
              }
            />

            <Route path="edit" element={<EditApplication />} />
            <Route path="edit/employment" element={<EditApplicationEmploymentHistoryContainer />} />

            <Route
              path="edit/summary"
              element={
                <TrackedPage pageName="Edit Summary - Application">
                  <EditApplicationSummary />
                </TrackedPage>
              }
            />
            <Route path="edit/quote" element={<EditApplicationRedirector />} />
            <Route path="edit/quote/vehicle" element={<EditApplicationVehicle />} />
            <Route path="edit/quote/costs" element={<EditApplicationQuote />} />

            <Route element={<EditApplicationBankDetailsContainer />}>
              <Route
                path="edit/bankdetails"
                element={
                  <TrackedPage pageName="Edit Bank Details - Application">
                    <EditApplicationBankDetails />
                  </TrackedPage>
                }
              />
            </Route>

            <Route element={<EditApplicationAffordabilityContainer />}>
              <Route path="edit/affordability" element={<EditApplicationAffordability />} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Route>
  </Routes>
);

export default routes;
