import { inject } from 'mobx-react';
import { useCallback, useState } from 'react';
import ChartFilters from '../../components/ChartFilter/ChartFilters';
import ChartContainer from '../../components/ChartContainer/ChartContainer';
import LineChart from '../../components/LineChart/LineChart';
import { Metric, FiltersState } from '../../types';

const SubmittedApplications = () => {
  const [filters, setFilters] = useState<FiltersState>();

  const handleFilterChange = useCallback(
    (chartFilters: FiltersState) => {
      setFilters(chartFilters);
    },
    [setFilters]
  );
  const metric = 'finance_application_initial_submission:count' as Metric;

  return (
    <ChartContainer>
      <ChartFilters
        metric={metric}
        defaults={{
          period: 7
        }}
        onFilterChange={handleFilterChange}
        enablePeriod
        includeDay
      />
      <LineChart metric={metric} filters={filters} />
    </ChartContainer>
  );
};

export default inject('appStore')(SubmittedApplications);
